import AppUtil from "../../../../common/appUtil";
import React from "react";
import {AppCheckBox} from "../../../../common/utils/useCommonElement";
import {ViewModeType} from "./wlTableRow";

export default function TableHeader({
                                        configs,
                                        viewModeType = ViewModeType.normal,
                                        headerClass = "",
                                        onChangeCheckbox = null,
                                        columnIds = null
                                    }) {
    return <thead className={headerClass}>
    <tr>
        {AppUtil.isAvailable(configs) && configs.map((tblTitle, index) => {
            return <th key={tblTitle.title + index} className={`th th--author ${tblTitle.className}`}>
                <>
                    {(viewModeType === ViewModeType.rowColumnSelection) &&
                        <AppCheckBox id={`${index}_${tblTitle.title}`}
                                     value={columnIds?.has(tblTitle?.columnId)}
                                     onChange={(e) => onChangeCheckbox?.(e.target.checked, tblTitle?.columnId)}
                        />
                    }
                    <div><span>{tblTitle.title}</span></div>
                </>
            </th>
        })
        }
        <th></th>
    </tr>
    </thead>;
}
