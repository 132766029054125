//@ts-nocheck
import useCaseOrder, {StandardPrice} from "./hooks/useCaseOrder";
import useOrderPricePlacesPopup from "./hooks/useOrderPricePlacesPopup";
import PricePlacesPopup from "./pricePlacesPopup";
import Button from "../../../common/button";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Lang} from "../../../common/appUtil";
import {getProductsForRequestedCategory, getProductTotalprice} from "../../../../actions/caseOrderAction";
import OrderUtil, {OrderProps} from "../../../common/orderUtil";
import {updateCaseOrderProperty} from "../../../../actions/caseAction";

const PricePlaceView = ({
                            pricePlace,
                            order,
                            loader,
                            dispatch,
                            orderIndex,
                            onSaveClick,
                            caseVersion,
                            hasCaseInfoChanged
                        }) => {

    const {
        getAllPricePlacesApi,
        isLoadingPlaces,
        pricePlaces,
        hasPricePlaces,
        hasNoPricePlaces,
    } = useCaseOrder();

    const {
        showPricePlacesPopup,
        onPopupConfirmation,
        showPopup,
        hidePopup,
        selectedPlaceOption,
        onSelectionChange,
        cleanupPopup,
        initPopup,
        isEnabledConfirmButton
    } = useOrderPricePlacesPopup(pricePlaces, pricePlace);

    const popupViewMount = () => {
        initPopup(pricePlace);
    };

    const popupViewUnmount = () => {
        cleanupPopup();
    };

    const onButtonClick = () => {
        getAllPricePlacesApi(true, true, (status, latestPricePlaces) => {
            if (status) {
                showOrSkipOrderPlaces(latestPricePlaces);
            }
        });
    };

    const showOrSkipOrderPlaces = (_pricePlaces) => {
        if (hasPricePlaces(_pricePlaces)) {
            showPopup();
        } else if (hasNoPricePlaces(_pricePlaces)) {
            //no op.
        } else {
            console.log("[DEBUG]:: Unhandled:: pricePlaces = ", _pricePlaces);
        }
    };

    /***
     * @description:
     * Step1: Redux store save, ie: order.pricePlace = payload,
     * Step2: Call save case
     */
    const delaySaveClickInMs = 1000;
    const saveCase = (placeId) => {
        console.log("[DEBUG] :: Save case :: Place id = ", placeId);
        const payload = (selectedPlaceOption && (selectedPlaceOption?.id !== 0)) ? selectedPlaceOption : null;
        dispatch(updateCaseOrderProperty(payload, OrderProps.pricePlace, order.id));

        if (onSaveClick) {
            setTimeout(() => {
                onSaveClick?.();
            }, delaySaveClickInMs);
        }
    };

    /***
     * @description: After successful save case, ie: version changes
     */
    useEffect(() => {
        if (caseVersion) {
            const placeId = order.pricePlace?.id;
            updateProductCategories(order, orderIndex, placeId);
        }
    }, [caseVersion]);

    const updateProductCategories = (_order, _orderIndex, _placeId) => {

        //Updates Total price, Category price, Category total
        dispatch(getProductTotalprice(_order.orderType, _orderIndex, "case"));

        //Update products list with correct price
        _order.events.forEach(event => {
            event.categories.forEach(category => {
                dispatch(getProductsForRequestedCategory(
                    category,
                    _order.orderType,
                    _orderIndex,
                    _order.packageType,
                    event.eventType,
                    _placeId,
                    "case"
                ));
            });
        });
    };

    const popupInfo = {
        showPopup: showPricePlacesPopup,
        popupTitle: Lang().cases.orderTabContent.pricePlaces.popup.placeChangeOrderUpdate,
        confirmationButtonTitle: Lang().cases.orderTabContent.pricePlaces.popup.changePriceRegion,
        onConfirmation: () => onPopupConfirmation(selectedPlaceOption, saveCase),
        disableConfirmButton: (isEnabledConfirmButton === false),
        hidePopup: hidePopup,
        popupViewMount: popupViewMount,
        popupViewUnmount: popupViewUnmount
    };

    const dropDownInfo = {
        dropDownTitle: Lang().cases.orderTabContent.pricePlaces.popup.priceRegion,
        dropdownOptions: pricePlaces,
        dropdownValue: selectedPlaceOption,
        isLoading: isLoadingPlaces,
        onDropdownSelectionChange: onSelectionChange,
        isDisabled: isLoadingPlaces || hasNoPricePlaces(pricePlaces)
    };
    const title = (name, id) => `${name ? name : `(${Lang().cases.orderTabContent.pricePlaces.unknown})`} ${id ? `- ${id}` : ""}`;
    const buttonTitle = (_pricePlace) => {
        if (_pricePlace) {
            return title(_pricePlace?.name, _pricePlace?.id);
        } else {
            return title(StandardPrice.name, StandardPrice.id);
        }
    };

    return <>
        {
            isLoadingPlaces ? loader?.start() : loader?.stop()
        }
        {
            showPricePlacesPopup
            && <PricePlacesPopup popupInfo={popupInfo}
                                 dropDownInfo={dropDownInfo}
            />
        }
        <Button
            isSecondary
            icon="group-location--color"
            iconIsColor
            onClick={onButtonClick}
            maxWidth
            type="submit"
            disabled={(pricePlace?.isPricePlaceEnabled === false) || isLoadingPlaces || (order.id === 0) || hasCaseInfoChanged || OrderUtil.lockProducts(order?.stage)}
            tooltip={Lang().cases.orderTabContent.pricePlaces.priceRegionButtonToolTip}
        >
            {buttonTitle(pricePlace)}
        </Button>
        {/*{*/}
        {/*    isLoadingOrderForPlaceChange*/}
        {/*    && <Placeholders>*/}
        {/*        <Placeholder maxWidth/>*/}
        {/*    </Placeholders>*/}
        {/*}*/}
    </>;
};

export default PricePlaceView;

PricePlaceView.propTypes = {
    pricePlace: PropTypes.object,
    order: PropTypes.object,
    loader: PropTypes.element,
    dispatch: PropTypes.func,
    orderIndex: PropTypes.number,
    onSaveClick: PropTypes.func,
    caseVersion: PropTypes.string,
    hasCaseInfoChanged: PropTypes.bool
};