//@ts-nocheck
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import useCaseOrder from "./hooks/useCaseOrder";
import useOrderPricePlacesPopup from "./hooks/useOrderPricePlacesPopup";
import {Lang} from "../../../common/appUtil";
import PricePlacesPopup from "./pricePlacesPopup";

const ShowOrSkipOrderPlaces = ({clickedOrderInfo, openOrderForm, loader}) => {
    const {order, clickCounter} = clickedOrderInfo;
    const {getAllPricePlacesApi, isLoadingPlaces, pricePlaces, hasPricePlaces, hasNoPricePlaces} = useCaseOrder();

    const createOrderApi = (placeId) => {
        openOrderForm(order, placeId);
    };

    const {
        showPricePlacesPopup,
        onPopupConfirmation,
        showPopup,
        hidePopup,
        selectedPlaceOption,
        onSelectionChange,
        cleanupPopup,
        initPopup,
    } = useOrderPricePlacesPopup(pricePlaces, null);

    /**
     * @description: For every order button click,
     * Call API , check places count>o
     * If yes, show popup, confirm : call "Create order" + placeId
     * else, call existing "Create order" + no place id API,
     */
    useEffect(() => {
        if (order?.id > 0) {
            getAllPricePlacesApi(true, true, (status, latestPricePlaces) => {
                if (status) {
                    showOrSkipOrderPlaces(latestPricePlaces);
                }
            });
        }
    }, [clickCounter]);

    const popupViewMount = () => {
        initPopup(null);
    };

    const popupViewUnmount = () => {
        cleanupPopup();
    };

    const showOrSkipOrderPlaces = (_pricePlaces) => {
        if (hasPricePlaces(_pricePlaces)) {
            showPopup();
        } else if (hasNoPricePlaces(_pricePlaces)) {
            openOrderForm(order, null);
        } else {
            console.log("[DEBUG]:: Unhandled:: pricePlaces = ", _pricePlaces);
        }
    };

    const popupInfo = {
        showPopup: showPricePlacesPopup,
        popupTitle: Lang().cases.orderTabContent.pricePlaces.popup.addOrder.replace("[ORDER_NAME]", `"${order?.name}"`),
        confirmationButtonTitle: Lang().cases.orderTabContent.pricePlaces.popup.createOrder,
        onConfirmation: () => onPopupConfirmation(selectedPlaceOption, createOrderApi),
        hidePopup: hidePopup,
        popupViewMount: popupViewMount,
        popupViewUnmount: popupViewUnmount
    };

    const dropDownInfo = {
        dropDownTitle: Lang().cases.orderTabContent.pricePlaces.popup.priceRegion,
        dropdownOptions: pricePlaces,
        dropdownValue: selectedPlaceOption,
        isLoading: isLoadingPlaces,
        onDropdownSelectionChange: onSelectionChange,
        isDisabled: isLoadingPlaces || hasNoPricePlaces(pricePlaces)
    };

    return (<>
        {
            isLoadingPlaces ? loader?.start() : loader?.stop()
        }
        {
            showPricePlacesPopup
            && <PricePlacesPopup popupInfo={popupInfo}
                                 dropDownInfo={dropDownInfo}
            />
        }
    </>);
};

ShowOrSkipOrderPlaces.propTypes = {
    clickedOrderInfo: PropTypes.object,
    openOrderForm: PropTypes.func,
    loader: PropTypes.element
};

export default ShowOrSkipOrderPlaces;
