import LocalizedStrings from "react-localization";
import Swedish from "./sv-SE";
import EnglishUS from "./en-US";
import EnglishGB from "./en-GB";
import {getLangStatus} from "../components/common/localStorageUtil";

export const getInternalizationConfig = (locale) => {
    switch (locale) {
        case Utility.enUS:
            return {locale: Utility.enUS, currency: Utility.currency.usd};
        case Utility.svSE:
            return {locale: Utility.svSE, currency: Utility.currency.sek};
        case Utility.enGB:
        default:
            return {locale: Utility.enGB, currency: Utility.currency.gbp};
    }
}

class Utility {

    static svSE = "sv-SE";
    static enUS = "en-US";
    static enGB = "en-GB";

    static LOGO_SE = "fenix_logo";
    static LOGO_GB = "fenix_funeral_logo";

    static selectedLang = {
        lang: "",
        strings: null
    };

    static culture = [
        {id: 1, name: Utility.svSE, title: "Svenska (Sverige)"},
        {id: 2, name: Utility.enUS, title: "English (US)"},
        {id: 3, name: Utility.enGB, title: "English (UK)"}
    ];

    static currency = {
        gbp: 'GBP',
        sek: 'SEK',
        usd: 'USD'
    }

    static getLang() {
        let currentLang = getLangStatus();

        if (Utility.selectedLang.lang === currentLang) {
            return Utility.selectedLang.strings;
        } else {
            Utility.selectedLang.lang = currentLang;
            console.log("Utility.getLang() - get string for current language", Utility.selectedLang.lang);
            switch (currentLang) {
                case Utility.enUS:
                    Utility.selectedLang.strings = new LocalizedStrings({
                        "en-US": EnglishUS.getTexts
                    });
                    break;
                case Utility.enGB:
                    Utility.selectedLang.strings = new LocalizedStrings({
                        "en-GB": EnglishGB.getTexts
                    });
                    break;
                default:
                    Utility.selectedLang.strings = new LocalizedStrings({
                        sv: Swedish.getTexts
                    });
                    break;
            }
            return Utility.selectedLang.strings;
        }
    }
}

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export default Utility;
