import {Lang} from "../../../../common/appUtil";
import {OrderProps} from "../../../../common/orderUtil";

export const WLSummaryTableConfig = [
    {
        className: 'td td--event',
        colKey: "orderTypeText",
        colDetailKey: OrderProps.orderCardNote,
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "nameFromCaseOrOrder",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "orderId",
        title: "",
    },
    {
        className: 'td',
        colKey: "userProfile",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalTime",
        title: "",
    },
    {
        className: 'td',
        colKey: "rowColumnSelection",
        title: "",
    }
];

export const WLBottomSummaryTableConfig = [
    {
        className: 'td td--event',
        colKey: "total",
        title: Lang().orders.sum,
    },
    {
        className: 'td',
        colKey: "nameFromCaseOrOrder",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "orderId",
        title: "",
    },
    {
        className: 'td',
        colKey: "userProfile",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "summaryOfTimeSpent",
        title: "",
    },
    {
        className: 'td',
        colKey: "download",
        title: "",
    }
];
