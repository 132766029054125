//@ts-nocheck
import {API} from "../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";
import ApiCollection from "../../../../api/apiCollections";

export const getWorkLogsByOrderId = async (caseId, orderId) => {
    const url = ApiCollection.properties.fetchWorkLogsByOrderId
        .replace('{CASE_ID}', caseId)
        .replace('{ORDER_ID}', orderId);

    const response = await API.get(getApiToken(), url);
    return response;
};

/***
 * @description:
 * @url: https://apistage.fenixbegravning.se/api/v1/case/6182/order/7254/worklogs/download?ltids=97|53&ltfns=WorkType|StartDate|EndDate|Time|Note
 */
export const downloadWorkLog = async (caseId, orderId, {logTimeIds = "", logTimeFields = null} = {}) => {
    let url = ApiCollection.properties.downloadWorkLog.replace("{CASE_ID}", caseId).replace("{ORDER_ID}", orderId);

    url += '?' + `ltids=${logTimeIds}`;

    if (logTimeFields) {
        url += '&' + `ltfns=${logTimeFields}`;
    }
    const response = await API.get(getApiToken(), url, {responseType: 'blob'});
    return response;
};