//@ts-nocheck
import {getAllPricePlaces} from "../api";
import ApiCollection from "../../../../../api/apiCollections";
import {useState} from "react";
import AppUtil, {Lang} from "../../../../common/appUtil";

export const StandardPrice = {
    id: 0,
    name: Lang().cases.orderTabContent.pricePlaces.dropdown.defaultOption,
};

//As "Standard" will be always present
const DEFAULT_PRICE_PLACES_LENGTH = 1;

const useCaseOrder = () => {

    const [isLoadingPlaces, setIsLoadingPlaces] = useState<boolean>(false);
    const [pricePlaces, setPricePlaces] = useState([]);

    /***
     * @description: on order click, call all price places then, popup or skip it
     */

    const pageLimit = 1000;
    const getAllPricePlacesApi = (filterPricePlace, filterPricePlaceEnabled, onCompletion, pageStartIndex = 0) => {

        let url = ApiCollection.properties.fetchAllPlaces.replace('{FILTER_PRICE_PLACE}', filterPricePlace);
        if (filterPricePlaceEnabled !== undefined && filterPricePlaceEnabled !== null) {
            url = url + `&f_pp_en=${filterPricePlaceEnabled}`;
        }

        url = url + `&psi=${pageStartIndex}`;

        if (pageLimit) {
            url = url + `&pl=${pageLimit}`;
        }

        setIsLoadingPlaces(true);
        let latestPricePlaces = null;
        getAllPricePlaces(url).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data?.object;
                console.log("[Debug]:: Response:: Price places = ", dataObj?.places);
                const newPlaces = (dataObj?.places !== undefined || dataObj?.places !== null)
                    ? [...dataObj.places]
                    : [];
                //Add standard price on top
                newPlaces.unshift(StandardPrice);

                setPricePlaces(newPlaces);
                latestPricePlaces = newPlaces;
            }
        }).finally(() => {
            setIsLoadingPlaces(false);
            onCompletion(true, latestPricePlaces);
        });
    };

    const hasPricePlaces = (places) => {
        return (places?.length > DEFAULT_PRICE_PLACES_LENGTH);
    };

    const hasNoPricePlaces = (places) => {
        return (!places || (places && places?.length === DEFAULT_PRICE_PLACES_LENGTH));
    };

    return {
        getAllPricePlacesApi,
        isLoadingPlaces,
        pricePlaces,
        hasPricePlaces,
        hasNoPricePlaces
    };
};

export default useCaseOrder;
