import AppUtil from "../common/appUtil";
import {getTenantKey} from "../common/localStorageUtil";
import Utility from "../../api/utilLanguage";

export class LoginUtil {
    static queryParameter = {
        tenantFenixSE: "FenixSE",
        tenantFenixUK: "FenixUK",
    }

    static getTenantKeyFromQueryParam = (tenantParam) => {
        let tenantKey = tenantParam;
        if (AppUtil.isAvailable(tenantKey)) {// If a tenant is set in the URL, use it as the tenantKey for this login
        } else {
            // If a tenant is not set in the URL, take the tenantKey from the local storage
            tenantKey = getTenantKey();
            if (AppUtil.isEmpty(tenantKey)) {// If the tenantKey is not available from local storage, use default tenant key "FenixSE" and set it in local storage
                tenantKey = LoginUtil.queryParameter.tenantFenixSE;
            }
        }
        return tenantKey;
    }

    /***
     * @description: Always set the login lang based on the tenant
     */
    static getLoginLangBasedOnTenantKey(tenantKey) {
        switch (tenantKey) {
            case LoginUtil.queryParameter.tenantFenixSE:
                return Utility.svSE;
            case LoginUtil.queryParameter.tenantFenixUK:
                return Utility.enGB;
            default:
                return Utility.svSE;
        }
    }

    static getLoginLogoBasedOnTenantKey(tenantKey) {
        switch (tenantKey) {
            case LoginUtil.queryParameter.tenantFenixSE:
                return Utility.LOGO_SE;
            case LoginUtil.queryParameter.tenantFenixUK:
                return Utility.LOGO_GB;
            default:
                return Utility.LOGO_SE;
        }
    }
}
