//@ts-nocheck
import useRowColumnSelectionPopup from "../../../case/caseLogs/UI/workLogsList/hooks/useRowColumnSelectionPopup";
import {useWorkLogConfig} from "../../../case/caseLogs/UI/workLogsList/hooks/useWorkLogList";
import React, {useEffect, useState} from 'react';
import LogTimeTable from "../../../case/caseLogs/UI/workLogsList/logTimeTable";
import {AppCheckBox} from "../../utils/useCommonElement";
import {Lang} from "../../appUtil";
import PropTypes from "prop-types";
import {updateWorklogPdfDetails} from "../../../../actions/caseAction";
import {OrderProps} from "../../orderUtil";
import useIncludeWorkLogSpecification from "./useIncludeWorkLogSpecification";

const IncludeWorkLogSpecification = ({
                                         isChecked,
                                         caseId,
                                         orderId,
                                         orderTypeText,
                                         logTimesForOrderId,
                                         onChangeCheckbox,
                                         dispatch
                                     }) => {
    const {
        onSummaryRowColumnSelectionButtonClick,
        selectedCaseInfo,
        showRowColumnSelectionPopup,
        hidePopup
    } = useRowColumnSelectionPopup();

    const {rowColumnSelectionWorkLogTableConfig} = useWorkLogConfig();
    const [tableSelectionInfo, setTableSelectionInfo] = useState({columnIds: new Set(), rowIds: new Set()});

    const {getWorklogPdfDetails} = useIncludeWorkLogSpecification();

    useEffect(() => {
        if (tableSelectionInfo.rowIds.size > 0 && tableSelectionInfo.columnIds.size > 0) {
            const payload = getWorklogPdfDetails(tableSelectionInfo?.rowIds, tableSelectionInfo?.columnIds);
            dispatch(updateWorklogPdfDetails(OrderProps.worklogPdfDetails, payload, orderId));
        }
    }, [tableSelectionInfo.rowIds.size, tableSelectionInfo.columnIds.size]);

    /***
     * @description: Show work log, row column Selection
     */
    const _onChangeCheckbox = (value, _caseId, _orderId, _orderTypeText) => {

        onChangeCheckbox?.(value, _caseId, _orderId);
        onSummaryRowColumnSelectionButtonClick(_caseId, _orderId, _orderTypeText, value);

        if (value === false) {//Unchecked
            dispatch(updateWorklogPdfDetails(OrderProps.worklogPdfDetails, {}, _orderId));
            hidePopup();
        }
    };

    return <>
        <AppCheckBox id={"includeWorkLogSpecification"}
                     title={Lang().common.changeStagePopup.includeWorkLogSpecification}
                     value={isChecked}
                     onChange={(e) => _onChangeCheckbox?.(e.target.checked, caseId, orderId, orderTypeText)}/>
        {
            showRowColumnSelectionPopup
            && <div className="inner">
                <LogTimeTable
                    logTimes={logTimesForOrderId}
                    configs={rowColumnSelectionWorkLogTableConfig}
                    caseId={selectedCaseInfo.caseId}
                    orderId={selectedCaseInfo.orderId}
                    dispatch={dispatch}
                    tableSelectionInfo={tableSelectionInfo}
                    setTableSelectionInfo={setTableSelectionInfo}
                    className={"table-scroll"}
                />
            </div>
        }
    </>;
};

export default IncludeWorkLogSpecification;

IncludeWorkLogSpecification.propTypes = {
    isChecked: PropTypes.bool,
    caseId: PropTypes.number,
    orderId: PropTypes.number,
    orderTypeText: PropTypes.string,
    logTimesForOrderId: PropTypes.number,
    dispatch: PropTypes.func,
    onChangeCheckbox: PropTypes.func
};
