import React from "react";
import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
import ValidationUtil, {
    allowNumberAndSpace,
    checkPersonNumberFormat,
    checkPostalCodeFormat,
    checkValidation,
    getNoSpaceError,
    getPersonNumberFormatError,
    getPostalCodeFormatError
} from "../../../common/validationUtil";
import {Field} from "redux-form";
import FormFieldForLogin from "../../../common/formFieldForLogin";
import Select from "react-select";
import ValidationAndVisibilityRule, {
    flaxLinkVisible,
    sparLinkVisible,
    validationRule
} from "../../../common/ValidationAndVisibilityRule";
import Button from "../../../common/button";
import DatePicker from "react-datepicker";
import DateUtilExt, {TIME_FORMAT, TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../common/datePicker/datePickerUtil";
import CaseUtil from "../../../common/caseUtil";
import {DeceasedUtil} from "../deceasedUtil";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import CreatableSelect from "react-select/lib/Creatable";

export default class Deceased {
    static FirstName({deceased, onBlur}) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedFirstName">
                {Utility.getLang().cases.deceasedTabContent.firstName}
            </label>
            <input
                name="firstName"
                id="caseDeceasedFirstName"
                placeholder={Utility.getLang().cases.deceasedTabContent.firstName}
                className={`text text--max_width ${AppUtil.isAvailable(deceased.firstName) && ValidationUtil.isInvalidName(deceased.firstName) ? "is-error" : ""}`}
                defaultValue={AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.firstName) ? deceased.firstName : null}
                onBlur={onBlur}
                type="text"
            />
            {
                AppUtil.isAvailable(deceased.firstName) && ValidationUtil.isInvalidName(deceased.firstName) && ValidationUtil.getNameError(deceased.firstName, Utility.getLang().cases.deceasedTabContent.firstName)
            }
        </React.Fragment>;
    }

    static LastName({deceased, onBlur}) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedLastName">
                {Utility.getLang().cases.deceasedTabContent.lastName}
            </label>
            <input
                name="lastName"
                id="caseDeceasedLastName"
                placeholder={Utility.getLang().cases.deceasedTabContent.lastName}
                className={`text text--max_width ${ValidationUtil.isInvalidLastName(deceased.lastName) ? "is-error" : ""}`}
                defaultValue={AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.lastName) ? deceased.lastName : null}
                onBlur={onBlur}
                type="text"
            />
            {
                ValidationUtil.isInvalidLastName(deceased.lastName) && ValidationUtil.getLastNameError(deceased.lastName)
            }
        </React.Fragment>;
    }

    static Address({deceased, onBlur}) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedAddress">
                {Utility.getLang().cases.deceasedTabContent.address}
            </label>
            <input
                name="address"
                id="caseDeceasedAddress"
                placeholder={Utility.getLang().cases.deceasedTabContent.address}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.address.address) ? deceased.address.address : null)}
                defaultValue={deceased !== undefined && deceased.address !== undefined && deceased.address.address !== undefined ? deceased.address.address : null}
                onBlur={onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.address.address) ? deceased.address.address : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static Address2({deceased, onBlur}) {
        return <React.Fragment>
            <input
                name="address2"
                id="caseDeceasedAddress2"
                placeholder={Utility.getLang().cases.deceasedTabContent.address2}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.address.address2) ? deceased.address.address2 : null)}
                defaultValue={deceased !== undefined && deceased.address !== undefined && deceased.address.address2 !== undefined ? deceased.address.address2 : null}
                onBlur={onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.address.address2) ? deceased.address.address2 : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static PostalCode({selectedCaseData, onBlur}) {
        const isPostalCodeValidationRequired = ValidationUtil.isRequiredPostalCodeValidation(selectedCaseData.deceased.address.country.countryCode);
        const postalCode = AppUtil.isAvailable(selectedCaseData.deceased) && AppUtil.isAvailable(selectedCaseData.deceased.address) && AppUtil.isAvailable(selectedCaseData.deceased.address.postalCode) ? selectedCaseData.deceased.address.postalCode : null;
        return <React.Fragment>
            <label htmlFor="caseDeceasedPostalCode">
                {Utility.getLang().cases.deceasedTabContent.postalCode}
            </label>
            <input
                name="postalCode"
                id="caseDeceasedPostalCode"
                placeholder={Utility.getLang().cases.deceasedTabContent.postalCode}
                className={`text text--zip text--max_width ${isPostalCodeValidationRequired ? checkValidation(postalCode) + checkPostalCodeFormat(postalCode) : ""}`}
                defaultValue={postalCode}
                onBlur={onBlur}
                type="text"
                onKeyPress={(event) =>
                    isPostalCodeValidationRequired ?
                        validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).onlyDigits === "true" ? allowNumberAndSpace(event) : ValidationUtil.allowNumberAndAlphabets(event)
                        : ""}
                autoComplete="off"
                data-lpignore="true"
            />
            {
                isPostalCodeValidationRequired
                    ? checkValidation(postalCode) !== "" ? getNoSpaceError() : checkPostalCodeFormat(postalCode) !== "" ? getPostalCodeFormatError() : null
                    : ""
            }
        </React.Fragment>;
    }

    static City(props: { selectedCaseData: any, onBlur: () => void }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedCity">
                {Utility.getLang().cases.deceasedTabContent.city}
            </label>
            <input
                name="city"
                id="caseDeceasedCity"
                placeholder={Utility.getLang().cases.deceasedTabContent.city}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.address.city) ? props.selectedCaseData.deceased.address.city : null)}
                defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.address !== undefined && props.selectedCaseData.deceased.address.city !== undefined ? props.selectedCaseData.deceased.address.city : null}
                onBlur={props.onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.address.city) ? props.selectedCaseData.deceased.address.city : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static MemberOfSwedishChurch(props: { checked: boolean | string | any, onChange: () => void }) {
        return <div className="option option--checkbox">
            <Field
                id="swedishChurch"
                name="swedishChurch"
                checked={props.checked}
                onChange={props.onChange}
                component={FormFieldForLogin}
                type="checkbox"
            />
            <label
                htmlFor="swedishChurch"
                className="option__description option__description--checkbox"
            >
                <span>{Utility.getLang().cases.deceasedTabContent.memberOfSwedishChurch}</span>
            </label>
        </div>;
    }

    static Country({deceased, countries, onChange}) {
        return <React.Fragment>
            <label
                htmlFor={'countries'}>
                {Utility.getLang().cases.deceasedTabContent.country}
            </label>
            <Select
                id={'countries'}
                name="countries"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                value={AppUtil.isAvailable(deceased.address.country.name) ? deceased.address.country : null}
                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                options={countries}
                onChange={onChange}
                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
            />
        </React.Fragment>
    }

    static CountryOfPassing(props: { selectedCaseData: any, countries: any, onChange: () => void }) {
        return <React.Fragment>
            <label
                htmlFor={'countryOfPassing'}>
                {Utility.getLang().cases.deceasedTabContent.countryOfPassing}
            </label>
            <Select
                id={'countryOfPassing'}
                name="countryOfPassing"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                value={AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.country.name) ? props.selectedCaseData.deceased.addressOfPassing.country : null}
                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                options={props.countries}
                onChange={props.onChange}
                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
            />
        </React.Fragment>
    }

    static County(props: { selectedCaseData: any, counties: any, onChange: () => void }) {
        return <React.Fragment>
            <label
                htmlFor={'county'}>
                {Utility.getLang().cases.deceasedTabContent.county}
            </label>
            <Select
                id={'county'}
                name="county"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                value={AppUtil.isAvailable(props.selectedCaseData.deceased.address.county.name) ? props.selectedCaseData.deceased.address.county : null}
                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                options={props.counties}
                onChange={props.onChange}
                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
            />
        </React.Fragment>
    }

    static CountyOfPassing(props: { selectedCaseData: any, counties: any, onChange: () => void }) {
        return <React.Fragment>
            <label
                htmlFor={'countyOfPassing'}>
                {Utility.getLang().cases.deceasedTabContent.countyOfPassing}
            </label>
            <Select
                id={'countyOfPassing'}
                name="countyOfPassing"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                value={AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.county.name) ? props.selectedCaseData.deceased.addressOfPassing.county : null}
                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                options={props.counties}
                onChange={props.onChange}
                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
            />
        </React.Fragment>
    }

    static PersonNumber(props: { selectedCaseData: any, onBlur: () => void, onKeyPress: () => void, onKeyDown: () => void, onChange: () => void, value: null | string | any, onClick: () => void, disabled: boolean | any }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedPersonNumber">
                {Utility.getLang().cases.deceasedTabContent.personalIdentityNumber}
            </label>
            <div className="btn-toolbar btn-toolbar--actions">
                <input
                    name="personNumber"
                    id="caseDeceasedPersonNumber"
                    placeholder={Utility.getLang().cases.deceasedTabContent.personalIdentityNumber}
                    className={"text btn-toolbar__field " + checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.personNumber) ? props.selectedCaseData.deceased.personNumber : null) + checkPersonNumberFormat(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.personNumber) ? props.selectedCaseData.deceased.personNumber : null)}
                    //defaultValue={this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.personNumber !== undefined ? this.props.selectedCaseData.deceased.personNumber : null}
                    onBlur={props.onBlur}
                    onKeyPress={props.onKeyPress}
                    onKeyDown={props.onKeyDown}
                    onChange={props.onChange}
                    value={props.value || ''}
                    type="text"
                />
                {
                    sparLinkVisible() ?
                        <Button
                            onClick={props.onClick}
                            id="getDetails"
                            noLabel
                            disabled={props.disabled}
                            tooltip={Utility.getLang().cases.deceasedTabContent.getDetailsBtn}
                            icon="download"
                        /> : null
                }
            </div>
            {
                checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.personNumber) ? props.selectedCaseData.deceased.personNumber : null) !== "" ?
                    getNoSpaceError() : checkPersonNumberFormat(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.personNumber) ? props.selectedCaseData.deceased.personNumber : null) !== "" ?
                        getPersonNumberFormatError() : null
            }
        </React.Fragment>;
    }

    static DateOfBirth({dateString, onChange}) {
        const dateFormat = validationRule(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.dateOfBirth).dateFormat;
        return <React.Fragment>
            <label htmlFor="caseDeceasedDateOfBirth">
                {Utility.getLang().cases.deceasedTabContent.dateOfBirth}
            </label>
            <DatePicker
                id="caseDeceasedDateOfBirth"
                dateFormat={dateFormat}
                selected={DateUtilExt.pickerDate(dateString)}
                onChange={onChange}
                maxDate={new Date()}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                isClearable
                autoComplete="off"
                data-lpignore="true"
                className="text text--date"
                customInput={datePickerCustomInput(TimestampFormat.DATE)}
            />
        </React.Fragment>;
    }

    static Gender(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label htmlFor="sex">
                {Utility.getLang().cases.deceasedTabContent.sex}
            </label>
            <Select
                id={"sex"}
                name="sex"
                className={"multiselect multiselect--min_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                isClearable={true}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getGenderOptions(), DeceasedUtil.deceasedUKFields.SEX)}
                value={CaseUtil.setSelectedValue(CaseUtil.getGenderOptions(), props.selectedCaseData.deceased.sex)}
            />
        </React.Fragment>;
    }

    static DateOfPassing({dateString, onChange}) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedDateOfPassing">
                {Utility.getLang().cases.deceasedTabContent.dateOfPassing}
            </label>
            <DatePicker
                id="caseDeceasedDateOfPassing"
                dateFormat={TimestampFormat.DATE}
                selected={DateUtilExt.pickerDate(dateString)}
                onChange={onChange}
                isClearable
                maxDate={new Date()}
                showWeekNumbers
                autoComplete="off"
                data-lpignore="true"
                className="text text--date"
                customInput={datePickerCustomInput(TimestampFormat.DATE)}
            />
        </React.Fragment>;
    }

    static LocationOfBody(props: { loadOptions: () => Promise, onChange: () => void, value: [] | any, onInputChange: ((inputValue: string, actionMeta: InputActionMeta, onInputChange: () => (string | void)) => (string | void)) | any, noOptionsMessage: () => string | *, loadingMessage: () => string | *, residentialCheckBoxValue: Boolean, onResidentialCheckBoxChange: () => void }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedLocationOfBody">
                {Utility.getLang().cases.deceasedTabContent.locationOfBody}
            </label>

            <Deceased.UseResidentialAddress residentialCheckBoxValue={props.residentialCheckBoxValue}
                                            onResidentialCheckBoxChange={props.onResidentialCheckBoxChange}/>
            <AsyncCreatableSelect
                inputId="caseDeceasedLocationOfBody"
                loadOptions={props.loadOptions}
                onChange={props.onChange}
                isClearable
                defaultOptions
                value={props.value}
                onInputChange={props.onInputChange}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                placeholder={Utility.getLang().common.dropDownOptionTypeToSearch}
                noOptionsMessage={props.noOptionsMessage}
                loadingMessage={props.loadingMessage}
                isDisabled={props.residentialCheckBoxValue}
            />
        </React.Fragment>;
    }

    static UseResidentialAddress({residentialCheckBoxValue, onResidentialCheckBoxChange}) {
        return <React.Fragment>
            <div className="option option--checkbox">
                <input
                    id="caseDeceasedUseResidentialAddress"
                    name="useResidentialAddress"
                    checked={residentialCheckBoxValue}
                    onChange={onResidentialCheckBoxChange}
                    type="checkbox"
                />
                <label
                    htmlFor="caseDeceasedUseResidentialAddress"
                    className="option__description option__description--checkbox">
                    {Utility.getLang().cases.deceasedTabContent.useResidentialAddress}
                </label>
            </div>
        </React.Fragment>;
    }

    static ParishName(props: { selectedCaseData: any, onBlur: () => void, onClick: () => void, disabled: boolean | any }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedParishName">
                {Utility.getLang().cases.deceasedTabContent.parishName}
            </label>
            <div className="btn-toolbar btn-toolbar--actions">
                <input
                    id="caseDeceasedParishName"
                    name="parishName"
                    placeholder={Utility.getLang().cases.deceasedTabContent.parishName}
                    className="text btn-toolbar__field"
                    defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.parishName !== undefined ? props.selectedCaseData.deceased.parishName : null}
                    onBlur={props.onBlur}
                    type="text"
                />
                {
                    flaxLinkVisible() ?
                        <Button
                            onClick={props.onClick}
                            id="getDetails"
                            noLabel
                            disabled={props.disabled}
                            tooltip={Utility.getLang().cases.deceasedTabContent.getDetailsBtn}
                            icon="download"
                        /> : null
                }
            </div>
        </React.Fragment>;
    }

    static ReferredToCoroner(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"referredToCoroner"}>
                {Utility.getLang().cases.deceasedTabContent.referredToCoroner}
            </label>
            <Select
                id={"referredToCoroner"}
                name="referredToCoroner"
                className={"multiselect multiselect--max_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getYesNoUnknownSelectOptions(), DeceasedUtil.deceasedUKFields.REFERRED_TO_CORONER)}
                value={CaseUtil.setSelectedValue(CaseUtil.getYesNoUnknownSelectOptions(), props.selectedCaseData.deceased.referredToCoroner)}
            />
        </React.Fragment>;
    }

    static DeathRegistered(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"deathRegistered"}>
                {Utility.getLang().cases.deceasedTabContent.deathRegistered}
            </label>
            <Select
                id={"deathRegistered"}
                name="deathRegistered"
                className={"multiselect multiselect--max_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getYesNoUnknownSelectOptions(), DeceasedUtil.deceasedUKFields.DEATH_REGISTERED)}
                value={CaseUtil.setSelectedValue(CaseUtil.getYesNoUnknownSelectOptions(), props.selectedCaseData.deceased.deathRegistered)}
            />
        </React.Fragment>;
    }

    static GreenFormStatus(props: { onChange: () => void, value: [] | any, formatCreateLabel: () => string, options: [], onInputItemChange: () => void }) {
        return <React.Fragment>
            <label
                htmlFor={"greenFormWith"}>
                {Utility.getLang().cases.deceasedTabContent.greenFormWith}
            </label>
            <CreatableSelect
                id={"greenFormWith"}
                name="greenFormWith"
                onChange={props.onChange}
                value={props.value}
                className={"multiselect multiselect--max_width"}
                classNamePrefix={"multiselect"}
                formatCreateLabel={props.formatCreateLabel}
                options={props.options}
                onInputItemChange={props.onInputItemChange}
            />
        </React.Fragment>;
    }

    static ReadyForCollection(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"readyForCollection"}>
                {Utility.getLang().cases.deceasedTabContent.readyForCollection}
            </label>
            <Select
                id={"readyForCollection"}
                name="readyForCollection"
                className={"multiselect multiselect--max_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getYesNoUnknownSelectOptions(), DeceasedUtil.deceasedUKFields.READY_FOR_COLLECTION)}
                value={CaseUtil.setSelectedValue(CaseUtil.getYesNoUnknownSelectOptions(), props.selectedCaseData.deceased.readyForCollection)}
            />
        </React.Fragment>;
    }

    static OtherDetails(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"otherDetails"}>
                {Utility.getLang().cases.deceasedTabContent.otherDetails}
            </label>
            <textarea
                name="otherDetails"
                id={"otherDetails"}
                className="textarea textarea--max_width"
                onChange={props.onChange}
                onBlur={props.onChange}
                defaultValue={props.selectedCaseData.deceased.otherDetails}
            />
        </React.Fragment>;
    }

    static ReleaseForm(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"releaseForm"}>
                {Utility.getLang().cases.deceasedTabContent.releaseForm}
            </label>
            <Select
                id={"releaseForm"}
                name="releaseForm"
                className={"multiselect multiselect--min_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getReleaseFormOptions(), DeceasedUtil.deceasedUKFields.RELEASE_FORM)}
                value={CaseUtil.setSelectedValue(CaseUtil.getReleaseFormOptions(), props.selectedCaseData.deceased.releaseForm)}
            />
        </React.Fragment>;
    }

    static CremationPapers(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"cremationPapers"}>
                {Utility.getLang().cases.deceasedTabContent.cremationPapers}
            </label>
            <Select
                id={"cremationPapers"}
                name="cremationPapers"
                className={"multiselect multiselect--min_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getCremationPaperOptions(), DeceasedUtil.deceasedUKFields.CREM_PAPER)}
                value={CaseUtil.setSelectedValue(CaseUtil.getCremationPaperOptions(), props.selectedCaseData.deceased.cremationPapers)}
            />
        </React.Fragment>;
    }

    static CremationPaperPaidBy(props: { onChange: () => void, value: [] | any, formatCreateLabel: () => string, options: [], onInputItemChange: () => void }) {
        return <React.Fragment>
            <label htmlFor={"cremationPaperPaidBy"}>
                {Utility.getLang().cases.deceasedTabContent.cremationPaperPaidBy}
            </label>
            <CreatableSelect
                id={"cremationPaperPaidBy"}
                name="cremationPaperPaidBy"
                onChange={props.onChange}
                value={props.value}
                isClearable
                className={"multiselect multiselect--max_width"}
                classNamePrefix={"multiselect"}
                formatCreateLabel={props.formatCreateLabel}
                options={props.options}
                onInputItemChange={props.onInputItemChange}
            />
        </React.Fragment>;
    }

    static PACEMAKER = "PACEMAKER";

    static Pacemaker(props: { onChange: () => void, deceased: any }) {
        return (<Deceased.DropDownWithOptions
            id={Deceased.PACEMAKER}
            headerTitle={Utility.getLang().cases.deceasedTabContent.pacemaker}
            selectOptions={DeceasedUtil.getPaceMakerOption(CaseUtil.getPacemakerOptions(), DeceasedUtil.deceasedUKFields.PACEMAKER)}
            isClearable={false}
            value={CaseUtil.setSelectedValue(CaseUtil.getPacemakerOptions(), props.deceased.paceMaker)}
            onChange={props.onChange}
        />);
    }

    static PaymentDetailsForCremationPapers(props: { selectedCaseData: any, onBlur: () => void }) {

        return (<Deceased.TextAreaWithLabel id={"paymentDetailsForCremationPapers"}
                                            headerTitle={Utility.getLang().cases.deceasedTabContent.paymentDetailsForCremationPapers}
                                            value={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.paymentDetailsForCremationPapers !== undefined ? props.selectedCaseData.deceased.paymentDetailsForCremationPapers : null}
                                            onBlur={props.onBlur}
        />);
    }

    static MaritalStatus(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label htmlFor="maritalStatus">
                {Utility.getLang().cases.deceasedTabContent.maritalStatus}
            </label>
            <Select
                id={"maritalStatus"}
                name="maritalStatus"
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                isClearable={true}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getMaritalStatusOptions(), DeceasedUtil.deceasedUKFields.MARITAL_STATUS)}
                value={CaseUtil.setSelectedValue(CaseUtil.getMaritalStatusOptions(), props.selectedCaseData.deceased.maritalStatus)}
            />
        </React.Fragment>;
    }

    static LastOccupation(props: { selectedCaseData: any, onBlur: () => void }) {
        return <React.Fragment>
            <label htmlFor="lastOccupation">
                {Utility.getLang().cases.deceasedTabContent.lastOccupation}
            </label>
            <input
                name="lastOccupation"
                id="lastOccupation"
                placeholder={Utility.getLang().cases.deceasedTabContent.lastOccupation}
                className={"text text--max_width"}
                defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.lastOccupation !== undefined ? props.selectedCaseData.deceased.lastOccupation : null}
                onBlur={props.onBlur}
                type="text"
            />
        </React.Fragment>;
    }

    static Address1OfPassing(props: { selectedCaseData: any, onBlur: () => void }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedAddress">
                {Utility.getLang().cases.deceasedTabContent.addressOfPassing}
            </label>
            <input
                name="address"
                id="caseDeceasedAddress"
                placeholder={Utility.getLang().cases.deceasedTabContent.address}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.address) ? props.selectedCaseData.deceased.addressOfPassing.address : null)}
                defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.addressOfPassing !== undefined && props.selectedCaseData.deceased.addressOfPassing.address !== undefined ? props.selectedCaseData.deceased.addressOfPassing.address : null}
                onBlur={props.onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.address) ? props.selectedCaseData.deceased.addressOfPassing.address : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static Address2OfPassing(props: { selectedCaseData: any, onBlur: () => void }) {
        return <React.Fragment>
            <input
                name="address2"
                id="caseDeceasedAddress2"
                placeholder={Utility.getLang().cases.deceasedTabContent.address2}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.address2) ? props.selectedCaseData.deceased.addressOfPassing.address2 : null)}
                defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.addressOfPassing !== undefined && props.selectedCaseData.deceased.addressOfPassing.address2 !== undefined ? props.selectedCaseData.deceased.addressOfPassing.address2 : null}
                onBlur={props.onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.address2) ? props.selectedCaseData.deceased.address.address2 : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static PostalCodeOfPassing({selectedCaseData, onBlur}) {
        const isPostalCodeValidationRequired = ValidationUtil.isRequiredPostalCodeValidation(selectedCaseData.deceased.addressOfPassing.country.countryCode);
        const postalCode = AppUtil.isAvailable(selectedCaseData.deceased) && AppUtil.isAvailable(selectedCaseData.deceased.addressOfPassing) && AppUtil.isAvailable(selectedCaseData.deceased.addressOfPassing.postalCode) ? selectedCaseData.deceased.addressOfPassing.postalCode : null;
        return <React.Fragment>
            <label htmlFor="caseDeceasedPostalCode">
                {Utility.getLang().cases.deceasedTabContent.postalCodeOfPassing}
            </label>
            <input
                name="postalCode"
                id="caseDeceasedPostalCode"
                placeholder={Utility.getLang().cases.deceasedTabContent.postalCode}
                className={`text text--zip text--max_width ${isPostalCodeValidationRequired ? checkValidation(postalCode) + checkPostalCodeFormat(postalCode) : ""}`}
                defaultValue={selectedCaseData.deceased !== undefined && selectedCaseData.deceased.addressOfPassing !== undefined && selectedCaseData.deceased.addressOfPassing.postalCode !== undefined ? selectedCaseData.deceased.addressOfPassing.postalCode : null}
                onBlur={onBlur}
                type="text"
                onKeyPress={(event) => isPostalCodeValidationRequired ? validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).onlyDigits === "true" ? allowNumberAndSpace(event) : ValidationUtil.allowNumberAndAlphabets(event) : ""}/>
            {
                isPostalCodeValidationRequired ?
                    checkValidation(postalCode) !== "" ?
                        getNoSpaceError() :
                        checkPostalCodeFormat(postalCode) !== "" ?
                            getPostalCodeFormatError() : null
                    : ""
            }
        </React.Fragment>;
    }

    static CityOfPassing(props: { selectedCaseData: any, onBlur: () => void }) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedCity">
                {Utility.getLang().cases.deceasedTabContent.cityOfPassing}
            </label>
            <input
                name="city"
                id="caseDeceasedCity"
                placeholder={Utility.getLang().cases.deceasedTabContent.city}
                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.city) ? props.selectedCaseData.deceased.addressOfPassing.city : null)}
                defaultValue={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.addressOfPassing !== undefined && props.selectedCaseData.deceased.addressOfPassing.city !== undefined ? props.selectedCaseData.deceased.addressOfPassing.city : null}
                onBlur={props.onBlur}
                type="text"
            />
            {
                checkValidation(AppUtil.isAvailable(props.selectedCaseData.deceased) && AppUtil.isAvailable(props.selectedCaseData.deceased.addressOfPassing.city) ? props.selectedCaseData.deceased.address.city : null) !== "" ?
                    getNoSpaceError() : null
            }
        </React.Fragment>;
    }

    static CauseOfDeath(props: { onChange: () => void, value: [] | string | any, formatCreateLabel: () => string, options: [], onInputItemChange: () => void }) {
        return <React.Fragment>
            <label
                htmlFor={"causeOfDeath"}>
                {Utility.getLang().cases.deceasedTabContent.causeOfDeath}
            </label>
            <CreatableSelect
                id={"causeOfDeath"}
                name="causeOfDeath"
                onChange={props.onChange}
                value={props.value}
                isClearable
                className={"multiselect multiselect--min_width"}
                classNamePrefix={"multiselect"}
                formatCreateLabel={props.formatCreateLabel}
                options={props.options}
                onInputItemChange={props.onInputItemChange}
            />
        </React.Fragment>;
    }

    static DetailsOfFurtherExamination(props: { selectedCaseData: any, onBlur: () => void }) {
        return (<Deceased.TextAreaWithLabel id={"detailsOfFurtherExamination"}
                                            headerTitle={Utility.getLang().cases.deceasedTabContent.detailsOfFurtherExamination}
                                            value={props.selectedCaseData.deceased !== undefined && props.selectedCaseData.deceased.detailsOfFurtherExamination !== undefined ? props.selectedCaseData.deceased.detailsOfFurtherExamination : null}
                                            onBlur={props.onBlur}
                                            disabled={props.selectedCaseData.deceased.furtherExaminationRequired > 1}
        />);
    }

    static FurtherExaminationRequired(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label
                htmlFor={"furtherExaminationRequired"}>
                {Utility.getLang().cases.deceasedTabContent.furtherExaminationRequired}
            </label>
            <Select
                id={"furtherExaminationRequired"}
                name="furtherExaminationRequired"
                className={"multiselect multiselect--min_width"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getYesNoUnknownSelectOptions(), DeceasedUtil.deceasedUKFields.EXAMINATION)}
                value={CaseUtil.setSelectedValue(CaseUtil.getYesNoUnknownSelectOptions(), props.selectedCaseData.deceased.furtherExaminationRequired)}
            />
        </React.Fragment>;
    }

    static LocationOfPassing(props: { onChange: () => void, value: [] | any, formatCreateLabel: () => string, options: [], onInputItemChange: () => void }) {
        return <React.Fragment>
            <label htmlFor="locationOfPassing">
                {Utility.getLang().cases.deceasedTabContent.locationOfPassing}
            </label>
            <CreatableSelect
                id={"locationOfPassing"}
                name="locationOfPassing"
                onChange={props.onChange}
                isClearable
                value={props.value}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                formatCreateLabel={props.formatCreateLabel}
                options={props.options}
                onInputItemChange={props.onInputItemChange}
            >
            </CreatableSelect>
        </React.Fragment>;
    }

    static TimeOfPassing({dateString, onChange}) {
        return <React.Fragment>
            <label htmlFor="caseDeceasedTimeOfPassing">
                {Utility.getLang().cases.deceasedTabContent.timeOfPassing}
            </label>
            <DatePicker
                id="caseDeceasedTimeOfPassing"
                selected={DateUtilExt.pickerDate(dateString)}
                onChange={onChange}
                isClearable
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={TIME_FORMAT}
                timeFormat={TIME_FORMAT}
                timeCaption="Time"
                fixedHeight
                autoComplete="off"
                data-lpignore="true"
                className="text text--time"
                customInput={datePickerCustomInput(TIME_FORMAT)}
            />
        </React.Fragment>;
    }

    static PhoneOfPassing(props: { selectedCaseData: any, onKeyPress: () => void, onChange: () => void, onBlur: () => void, value: null | string | any }) {
        return <React.Fragment>
            <label
                htmlFor={"caseDeceasedPhoneNumber"}>
                {Utility.getLang().cases.deceasedTabContent.phoneOfPassing}
            </label>
            <input
                id={"caseDeceasedPhoneNumber"}
                name="caseDeceasedPhoneNumber"
                className={`text text--max_width text--number ${ValidationUtil.isInvalidPhoneWithGivenRegex(props.selectedCaseData.deceased.phoneOfPassing, new RegExp(validationRule(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.phoneOfPassing).regex)) ? "is-error" : ""}`}
                type="text"
                onKeyPress={props.onKeyPress}
                onChange={props.onChange}
                onBlur={props.onBlur}
                defaultValue={props.value}
                autoComplete="off"
                data-lpignore="true"
                backordeletekeypress="true"
            />
            {
                ValidationUtil.isInvalidPhoneWithGivenRegex(props.selectedCaseData.deceased.phoneOfPassing, new RegExp(validationRule(ValidationAndVisibilityRule.deceased, ValidationAndVisibilityRule.phoneOfPassing).regex)) && ValidationUtil.getPhoneNumberError()
            }
        </React.Fragment>;
    }

    static InfectionStatus(props: { onChange: () => void, selectedCaseData: any }) {
        return <React.Fragment>
            <label htmlFor="infectionStatus">
                {Utility.getLang().cases.deceasedTabContent.infectionStatus}
            </label>
            <Select
                id={"infectionStatus"}
                name="infectionStatus"
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                onChange={props.onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getInfectionStatusOptions(), DeceasedUtil.deceasedUKFields.INFECTION_STATUS)}
                value={CaseUtil.setSelectedValue(CaseUtil.getInfectionStatusOptions(), props.selectedCaseData.deceased.infectionStatus)}
            />
        </React.Fragment>;
    }

    static RELATIVES_AND_EXECUTORS_INFORMED = DeceasedUtil.deceasedUKFields.RELATIVES_AND_EXECUTORS_INFORMED;
    static NOT_INFORMED_DETAILS = DeceasedUtil.deceasedUKFields.NOT_INFORMED_DETAILS;
    static CREMATION_OBJECTIONS = DeceasedUtil.deceasedUKFields.CREMATION_OBJECTIONS;
    static OBJECTIONS_DETAILS = DeceasedUtil.deceasedUKFields.OBJECTIONS_DETAILS;
    static ASHES_DISPOSAL_TYPE_FOR_CREM1 = DeceasedUtil.deceasedUKFields.ASHES_DISPOSAL_TYPE_FOR_CREM1;
    static ASHES_INSTRUCTIONS_FOR_CREM1 = DeceasedUtil.deceasedUKFields.ASHES_INSTRUCTIONS_FOR_CREM1;

    static RelativesAndExecutorsInformed(props: { onChange: () => void, deceased: any }) {
        return (<Deceased.DropDownWithOptions id={Deceased.RELATIVES_AND_EXECUTORS_INFORMED}
                                              headerTitle={Utility.getLang().cases.deceasedTabContent.relativesAndExecutorsInformed}
                                              selectOptions={CaseUtil.getDropDownOptionsAsValueAndLabel(DeceasedUtil.getRelativesAndExecutorsInformedSelectOptions(), DeceasedUtil.deceasedUKFields.RELATIVES_AND_EXECUTORS_INFORMED)}
                                              value={CaseUtil.setSelectedValue(DeceasedUtil.getRelativesAndExecutorsInformedSelectOptions(), props.deceased.relativesAndExecutorsInformed)}
                                              onChange={props.onChange}
        />);
    }

    static NotInformedDetails(props: { deceased: any, onBlur: () => void }) {
        return (<Deceased.TextAreaWithLabel id={Deceased.NOT_INFORMED_DETAILS}
                                            headerTitle={Utility.getLang().cases.deceasedTabContent.notInformedDetails}
                                            value={AppUtil.isAvailable(props.deceased.notInformedDetails) ? props.deceased.notInformedDetails : null}
                                            onBlur={props.onBlur}
                                            disabled={props.deceased.relativesAndExecutorsInformed !== DeceasedUtil.relativesAndExecutorsInformed.allNotInformed}
        />);
    }

    static CremationObjections(props: { onChange: () => void, deceased: any }) {
        return (<Deceased.DropDownWithOptions id={Deceased.CREMATION_OBJECTIONS}
                                              headerTitle={Utility.getLang().cases.deceasedTabContent.cremationObjections}
                                              selectOptions={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getYesNoUnknownSelectOptions(), DeceasedUtil.deceasedUKFields.CREMATION_OBJECTIONS)}
                                              value={CaseUtil.setSelectedValue(CaseUtil.getYesNoUnknownSelectOptions(), props.deceased.cremationObjections)}
                                              onChange={props.onChange}
        />);
    }

    static ObjectionsDetails(props: { deceased: any, onBlur: () => void }) {
        return (<Deceased.TextAreaWithLabel id={Deceased.OBJECTIONS_DETAILS}
                                            headerTitle={Utility.getLang().cases.deceasedTabContent.objectionsDetails}
                                            value={AppUtil.isAvailable(props.deceased.objectionsDetails) ? props.deceased.objectionsDetails : null}
                                            onBlur={props.onBlur}
                                            disabled={props.deceased.cremationObjections !== DeceasedUtil.commonOptions.yes}
        />);
    }

    static AshesDisposalTypeForCrem1(props: { onChange: () => void, deceased: any }) {
        return (<Deceased.DropDownWithOptions id={Deceased.ASHES_DISPOSAL_TYPE_FOR_CREM1}
                                              headerTitle={Utility.getLang().cases.deceasedTabContent.ashesDisposalTypeForCrem1}
                                              selectOptions={CaseUtil.getDropDownOptionsAsValueAndLabel(DeceasedUtil.getAshesDisposalTypeForCrem1SelectOptions(), DeceasedUtil.deceasedUKFields.ASHES_DISPOSAL_TYPE_FOR_CREM1)}
                                              value={CaseUtil.setSelectedValue(DeceasedUtil.getAshesDisposalTypeForCrem1SelectOptions(), props.deceased.ashesDisposalTypeForCrem1)}
                                              onChange={props.onChange}
        />);
    }

    static AshesInstructionsForCrem1(props: { deceased: any, onBlur: () => void }) {
        return (<Deceased.TextAreaWithLabel id={Deceased.ASHES_INSTRUCTIONS_FOR_CREM1}
                                            headerTitle={Utility.getLang().cases.deceasedTabContent.ashesInstructionsForCrem1}

                                            value={AppUtil.isAvailable(props.deceased.ashesInstructionsForCrem1) ? props.deceased.ashesInstructionsForCrem1 : null}
                                            onBlur={props.onBlur}
        />);


    }

    static BODY_SIZE = "bodySize";

    static BodySize({onChange, selectedCaseData, id = Deceased.BODY_SIZE}) {
        return <React.Fragment>
            <label htmlFor={id}>
                {Utility.getLang().cases.deceasedTabContent.bodySize}
            </label>
            <Select
                id={id}
                name={id}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                onChange={onChange}
                options={CaseUtil.getDropDownOptionsAsValueAndLabel(DeceasedUtil.getBodySizeOptions(), id)}
                value={CaseUtil.setSelectedValue(DeceasedUtil.getBodySizeOptions(), selectedCaseData.deceased.bodySize)}
            />
        </React.Fragment>;
    }

    static BodySizeNotes({selectedCaseData, onChange}) {
        const bodySizeNotes = AppUtil.isAvailable(selectedCaseData.deceased) && AppUtil.isAvailable(selectedCaseData.deceased.bodySizeNotes) ? selectedCaseData.deceased.bodySizeNotes : ""
        return <React.Fragment>
            <label htmlFor="bodySizeNotes">
                {Utility.getLang().cases.deceasedTabContent.bodySizeNotes}
            </label>
            <textarea
                name="bodySizeNotes"
                id="bodySizeNotes"
                className="textarea textarea--max_width"
                defaultValue={bodySizeNotes}
                onBlur={onChange}
                onChange={onChange}
            />
        </React.Fragment>;
    }

    static DropDownWithOptions({
                                   id,
                                   headerTitle,
                                   selectOptions,
                                   value,
                                   onChange,
                                   isClearable = true,
                                   className = "multiselect multiselect--max_width"
                               }) {
        return <React.Fragment>
            <label
                htmlFor={id}>
                {headerTitle}
            </label>
            <Select
                id={id}
                name={id}
                className={className}
                classNamePrefix={"multiselect"}
                options={selectOptions}
                value={value}
                isClearable={isClearable}
                onChange={onChange}
            />
        </React.Fragment>
    }

    static TextAreaWithLabel({id, headerTitle, placeholder, value, onBlur, disabled}) {
        return <React.Fragment>
            <label htmlFor={id}>
                {headerTitle}
            </label>
            <textarea rows="3"
                      name={id}
                      id={id}
                      placeholder={placeholder}
                      className={"textarea text--max_width"}
                      defaultValue={value}
                      onBlur={onBlur}
                      disabled={disabled}
            />
        </React.Fragment>
    }
}
